import { css } from "@emotion/react";
import { rgba } from "polished";
import { Button, NavLinkRaw, Section } from "../components";
import Theme from "../components/Theme";
import IconCheck from "../images/check.inline.svg";

const flex = {
  lead: css`
    flex: 2 2 0;
    padding-right: 48px;
    margin-right: 16px;
  `,
  tier: css`
    flex: 1 1 0;
    margin-left: 18px;
  `,
};

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  back: css`
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    ${Theme.mq._sm} {
      display: none;
    }
  `,
  backLead: css`
    ${flex.lead};

    border-radius: 16px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 5%, rgba(255, 255, 255, 0) 95%);
    opacity: 0.15;
    margin-left: -48px;
    padding-left: 48px;
    margin-top: 350px;
  `,
  backTier: css`
    ${flex.tier};
    border-radius: 16px;
    border: solid 1px ${Theme.palette.border};
    position: relative;

    ::after {
      content: "";
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      height: 49px;
      background: ${Theme.palette.blue};
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    &[data-recommended="true"] {
      border-color: ${Theme.palette.active};
      ::after {
        background: ${Theme.palette.active};
      }

      background: radial-gradient(
        circle at 250% 60%,
        ${rgba(Theme.palette.glow, 0.5)} 0%,
        ${rgba(Theme.palette.gray, 0.25)} 100%
      );
    }
  `,
  head: css`
    display: flex;
    flex-direction: row;
  `,
  headLead: css`
    ${Theme.mq.md_} {
      ${flex.lead};
    }

    ${Theme.mq._sm} {
      display: none;
    }
  `,
  headTier: css`
    ${Theme.mq.md_} {
      ${flex.tier};
    }

    ${Theme.mq._sm} {
      flex: 1 1 0;

      & + & {
        border-left: solid 1px ${Theme.palette.border};
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    > h4 {
      ${Theme.typography.body.bold};
      text-transform: uppercase;
      letter-spacing: 1px;
      color: ${Theme.palette.background};
      padding: 12px 0;
      width: 100%;
      margin-bottom: 30px;
    }

    ${Theme.mq._sm} {
      > h4 {
        background: ${Theme.palette.blue};
      }
      &[data-recommended="true"] > h4 {
        background: ${Theme.palette.active};
      }
    }

    > h5,
    > h6,
    > p {
      margin-left: 24px;
      margin-right: 24px;
    }

    > h5,
    > h6 {
      ${Theme.fonts.medium};
    }

    > h6 {
      margin-top: 42px;
    }

    > h5 + p {
      margin-top: 12px;
    }

    > h6 + p {
      margin-top: 18px;
      margin-bottom: 24px;
    }

    > a,
    > button {
      margin-top: auto;
    }
  `,
  group: css`
    display: flex;
    flex-direction: column;
    > h5 {
      flex: 0 1 auto;
      ${Theme.mq._sm} {
        text-align: center;
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      margin-top: 30px;

      ${Theme.mq._sm} {
        flex-wrap: wrap;
      }
    }

    margin: 48px 0 48px 0;
    & + & {
      margin: 12px 0 48px 0;
    }
  `,
  lead: css`
    ${Theme.mq.md_} {
      ${flex.lead};
      align-items: flex-start;
    }

    ${Theme.mq._sm} {
      flex: 1 0 100%;
      width: 100%;
      margin-bottom: 30px;
      align-items: center;
      text-align: center;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;

    > h6 {
      ${Theme.fontSizes.large};
      ${Theme.fonts.medium};
      color: ${Theme.palette.text};

      & + p {
        margin-top: 6px;
      }

      & > a {
        color: ${Theme.palette.text};
        text-decoration: underline;
      }
    }
  `,
  cell: css`
    ${Theme.mq.md_} {
      ${flex.tier};
    }

    ${Theme.mq._sm} {
      flex: 1 1 0;

      & + & {
        border-left: solid 1px ${Theme.palette.border};
      }
    }

    display: flex;
    align-items: center;
    justify-content: center;

    ${Theme.typography.body.medium};

    > svg {
      display: block;
      width: 32px;
      height: 32px;
      fill: ${Theme.palette.text};
    }
  `,
  footnote: css`
    text-align: center;
    margin-top: 36px;
  `,
};

export type Tier = string | number;

export type SectionComparisonTableItem<T extends Tier> = {
  [tier in T]: string;
};

export interface SectionComparisonTableProps<T extends Tier> {
  footnote?: string;
  tiers: Array<{
    type: T;
    name: string;
    action?: {
      text: string;
      href: string;
    };
    heading: string;
    content: string;
    price: string;
    priceNote: string;
    recommended: boolean;
  }>;
  groups: Array<{
    active: boolean;
    name: string;
    items: Array<
      {
        active: boolean;
        name: string;
        description?: string;
        href?: string;
      } & SectionComparisonTableItem<T>
    >;
  }>;
}

export const SectionComparisonTable = <T extends Tier>({ footnote, tiers, groups }: SectionComparisonTableProps<T>) => (
  <Section marginBottom>
    <div css={styles.body}>
      <div css={styles.back}>
        <div css={styles.backLead} />
        {tiers.map(({ recommended }, index) => (
          <div key={`t${index}`} data-recommended={recommended} css={styles.backTier} />
        ))}
      </div>
      <div css={styles.head}>
        <div css={styles.headLead} />
        {tiers.map(({ name, heading, content, price, priceNote, action, recommended }, index) => (
          <div key={`t${index}`} data-recommended={recommended} css={styles.headTier}>
            <h4>{name}</h4>
            <h5 css={Theme.typography.h5}>{price}</h5>
            <p css={Theme.typography.body.medium} dangerouslySetInnerHTML={{ __html: priceNote }} />
            <h6 css={Theme.typography.h6}>{heading}</h6>
            <p css={Theme.typography.body.medium} dangerouslySetInnerHTML={{ __html: content }} />
            {action && action.text && (
              <Button appearance={recommended ? "primary" : "secondary"} height={48} href={action.href}>
                {action.text}
              </Button>
            )}
          </div>
        ))}
      </div>
      {groups
        .filter(({ active }) => active)
        .map(({ name, items }, index) => (
          <div key={`g${index}`} css={styles.group}>
            <h5 css={Theme.typography.h5}>{name}</h5>
            {items
              .filter(({ active }) => active)
              .map(({ active, name, description, href, ...other }, index) => {
                const o = other as unknown as SectionComparisonTableItem<T>;
                return (
                  <div key={`i${index}`}>
                    <div css={styles.lead}>
                      <h6>{href ? <NavLinkRaw to={href}>{name}</NavLinkRaw> : name}</h6>
                      {description && (
                        <p css={Theme.typography.body.medium} dangerouslySetInnerHTML={{ __html: description }} />
                      )}
                    </div>
                    {tiers.map(({ type }, index) => (
                      <div key={`t${index}`} css={styles.cell}>
                        {o[type] === "check" ? <IconCheck /> : o[type] || <span>&nbsp;</span>}
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>
        ))}
    </div>
    {footnote && (
      <p css={[Theme.typography.body.regular, styles.footnote]} dangerouslySetInnerHTML={{ __html: footnote }} />
    )}
  </Section>
);
